<template>
  <main>
    <h4>woob Home</h4>
    <div class="m-auto">
      <h5>Your Position</h5>
      latitude: {{ currentPosition.lat.toFixed(2) }}, longitude:
      {{ currentPosition.lng.toFixed(2) }}
    </div>
    <div ref="mapDiv" style="width: 100%; height: 80vh" />
    <div v-for="building in buildings" :key="building.name" class="row mb-3">
      <div class="col">
        <i class="bi bi-building"></i>
      </div>
      <div class="col">
        {{ building.name }}
      </div>
    </div>
  </main>
</template>

<script>
/* eslint-disable no-undef */
import getCollection from "@/composables/getCollection";
import { Loader } from "@googlemaps/js-api-loader";
import { onMounted } from "@vue/runtime-core";
import { useGeolocation } from "@/composables/useGeolocation";
import { computed, ref } from "@vue/reactivity";

export default {
  name: "Home",
  setup() {
    const googleMapsAPIKey = ref(process.env.VUE_APP_GOOGLE_MAPS_API_KEY);
    const { error, documents: buildings } = getCollection(
      "buildings",
      [
        ["active", "==", true],
        ["order", ">", 0]
      ],
      [
        ["order", "asc"],
        ["name", "asc"]
      ]
    );
    const loader = new Loader({ apiKey: googleMapsAPIKey.value });
    const mapDiv = ref(null);

    const { coords } = useGeolocation();
    const currentPosition = computed(() => ({
      lat: coords.value.latitude,
      lng: coords.value.longitude
    }));

    onMounted(async () => {
      console.log(`API Key: ${googleMapsAPIKey.value}`);
      await loader.load();
      new google.maps.Map(mapDiv.value, {
        center: currentPosition.value,
        zoom: 10
      });
    });

    return { error, buildings, currentPosition, mapDiv };
  }
};
</script>
